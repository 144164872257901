h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
}

h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.029375rem;
  font-weight: 600;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.029375rem;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
