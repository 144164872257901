@import "~normalize.css";
@import "fonts";
@import "typography";
@import "sizes";
@import "theme-default";
@import "theme-dark";

:root {
  font-size: 16px;
  --vh100-offset: 0px;
}

.dark-theme {
  @include theme-dark;
}

html {
  touch-action: manipulation;
  font-feature-settings: "rlig" 1, "calt" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--geist-background);

  @media print {
    //transform: scale(0.75);
    //transform-origin: 0 0;
  }
}

body {
  --color: var(--geist-foreground);

  background: var(--geist-background);
  color: var(--color);
  font-family: var(--font-family);
}

*,
:after,
:before {
  box-sizing: border-box;
}

@page {
  margin: 0;
}

.print-only {
  @media not print {
    display: none;
  }
}
