@mixin theme-dark {
  --geist-foreground: #fff;
  --geist-background: #000;
  --geist-selection: var(--geist-highlight-purple);
  --accents-8: #fafafa;
  --accents-7: #eaeaea;
  --accents-6: #999;
  --accents-5: #888;
  --accents-4: #666;
  --accents-3: #444;
  --accents-2: #333;
  --accents-1: #111;
  --geist-secondary-lighter: var(--accents-2);
  --geist-secondary-light: var(--accents-3);
  --geist-secondary: var(--accents-5);
  --geist-secondary-dark: var(--accents-7);
  --geist-link-color: var(--geist-success-light);
  --geist-marketing-gray: var(--accents-1);
  --geist-code: var(--geist-foreground);
  --geist-error-light: #f33;
  --geist-error: red;
  --geist-error-dark: #e60000;
  --geist-text-gradient: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.75));
  --dropdown-box-shadow: 0 0 0 1px var(--accents-2);
  --dropdown-triangle-stroke: #333;
  --scroller-start: #000;
  --scroller-end: transparent;
  --header-background: rgba(0, 0, 0, 0.5);
  --header-border-bottom: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.1);
  --header-import-flow-background: hsla(0, 0%, 7%, 0.8);
  --shadow-smallest: 0 0 0 1px var(--accents-2);
  --shadow-extra-small: 0 0 0 1px var(--accents-2);
  --shadow-small: 0 0 0 1px var(--accents-2);
  --shadow-medium: 0 0 0 1px var(--accents-2);
  --shadow-large: 0 0 0 1px var(--accents-2);
  --shadow-sticky: 0 0 0 1px var(--accents-2);
  --shadow-hover: 0 0 0 1px var(--geist-foreground);
  --portal-opacity: 0.75;
  --geist-violet-background: #291d3a;
  --geist-violet-background-secondary: #211830;
  --geist-violet-background-tertiary: #211830;
  --geist-background-rgb: 0, 0, 0;
  --geist-foreground-rgb: 255, 255, 255;
  --geist-console-header: #0f0310;
  --geist-console-purple: #8a63d2;
  --geist-console-text-color-default: var(--geist-foreground);
  --geist-console-text-color-blue: #3291ff;
  --geist-console-text-color-pink: #eb367f;
  --next-icon-border: #fff;
  --inset-border: 1px solid hsla(0, 0%, 100%, 0.1);
  --scroller-gradient: #000 0, rgba(0, 0, 0, 0.7) 8px, rgba(0, 0, 0, 0.45) 14px, rgba(0, 0, 0, 0.3) 19px,
    rgba(0, 0, 0, 0.26) 21px, rgba(0, 0, 0, 0.19) 25px, rgba(0, 0, 0, 0.12) 29px, rgba(0, 0, 0, 0.06) 32px,
    rgba(0, 0, 0, 0.03) 34px, rgba(0, 0, 0, 0.02) 36px, rgba(0, 0, 0, 0.008) 38px, rgba(0, 0, 0, 0.002) 39px,
    transparent 40px;
}
