:root {
  --geist-success-lighter: #d3e5ff;
  --geist-success-light: #3291ff;
  --geist-success: #0070f3;
  --geist-success-dark: #0761d1;
  --geist-error-lighter: #f7d4d6;
  --geist-error-light: #ff1a1a;
  --geist-error: #e00;
  --geist-error-dark: #c50000;
  --geist-warning-lighter: #ffefcf;
  --geist-warning-light: #f7b955;
  --geist-warning: #f5a623;
  --geist-warning-dark: #ab570a;
  --geist-violet-lighter: #d8ccf1;
  --geist-violet-light: #8a63d2;
  --geist-violet: #7928ca;
  --geist-violet-dark: #4c2889;
  --geist-violet-background: #fff;
  --geist-violet-background-secondary: #291c3a;
  --geist-violet-background-tertiary: #eae5f4;
  --geist-background-rgb: 255, 255, 255;
  --geist-foreground-rgb: 0, 0, 0;
  --geist-console-header: #efe7ed;
  --geist-console-purple: #7928ca;
  --geist-console-text-color-default: var(--geist-foreground);
  --geist-console-text-color-blue: #0070f3;
  --geist-console-text-color-pink: #eb367f;
  --geist-console-text-color-purple: #7928ca;
  --geist-cyan-lighter: #aaffec;
  --geist-cyan-light: #79ffe1;
  --geist-cyan: #50e3c2;
  --geist-cyan-dark: #29bc9b;
  --geist-highlight-purple: #f81ce5;
  --geist-highlight-magenta: #eb367f;
  --geist-highlight-pink: #ff0080;
  --geist-highlight-yellow: #fff500;
  --geist-foreground: #000;
  --geist-background: #fff;
  --geist-selection: var(--geist-cyan-light);
  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-3: #999;
  --accents-4: #888;
  --accents-5: #666;
  --accents-6: #444;
  --accents-7: #333;
  --accents-8: #111;
  --geist-link-color: var(--geist-success);
  --geist-marketing-gray: #fafbfc;
  --geist-code: var(--geist-foreground);
  --geist-secondary-lighter: var(--accents-2);
  --geist-secondary-light: var(--accents-3);
  --geist-secondary: var(--accents-5);
  --geist-secondary-dark: var(--accents-7);
  --dropdown-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  --dropdown-triangle-stroke: #fff;
  --scroller-start: #fff;
  --scroller-end: hsla(0, 0%, 100%, 0);
  --shadow-smallest: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --shadow-extra-small: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-small: 0 5px 10px rgba(0, 0, 0, 0.12);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --shadow-large: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-hover: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-sticky: 0 12px 10px -10px rgba(0, 0, 0, 0.12);
  --portal-opacity: 0.25;
  --wv-green: #0cce6b;
  --wv-orange: #ffa400;
  --wv-red: #ff4e42;
  --next-icon-border: #000;
  --scroller-gradient: #fff 0, hsla(0, 0%, 100%, 0.7) 8px, hsla(0, 0%, 100%, 0.45) 14px, hsla(0, 0%, 100%, 0.3) 19px,
    hsla(0, 0%, 100%, 0.26) 21px, hsla(0, 0%, 100%, 0.19) 25px, hsla(0, 0%, 100%, 0.12) 29px,
    hsla(0, 0%, 100%, 0.06) 32px, hsla(0, 0%, 100%, 0.03) 34px, hsla(0, 0%, 100%, 0.02) 36px,
    hsla(0, 0%, 100%, 0.008) 38px, hsla(0, 0%, 100%, 0.002) 39px, hsla(0, 0%, 100%, 0) 40px;

  --ds-gray-100: #f5f5f5;
  --ds-gray-200: #ebebeb;
  --ds-gray-300: #e6e6e6;
  --ds-gray-400: #ebebeb;
  --ds-gray-500: #c9c9c9;
  --ds-gray-600: #a8a8a8;
  --ds-gray-700: #8f8f8f;
  --ds-gray-800: #7d7d7d;
  --ds-gray-900: #666;
  --ds-gray-1000: #171717;
  --ds-gray-alpha-100: #f5f5f5;
  --ds-gray-alpha-200: rgba(0, 0, 0, 0.08);
  --ds-gray-alpha-300: rgba(0, 0, 0, 0.1);
  --ds-gray-alpha-400: rgba(0, 0, 0, 0.08);
  --ds-gray-alpha-500: rgba(0, 0, 0, 0.21);
  --ds-gray-alpha-600: rgba(0, 0, 0, 0.34);
  --ds-gray-alpha-700: rgba(0, 0, 0, 0.44);
  --ds-gray-alpha-800: rgba(0, 0, 0, 0.51);
  --ds-gray-alpha-900: rgba(0, 0, 0, 0.61);
  --ds-gray-alpha-1000: rgba(0, 0, 0, 0.91);
  --ds-blue-100: #f5faff;
  --ds-blue-200: #ebf5ff;
  --ds-blue-300: #e0f0ff;
  --ds-blue-400: #e0f0ff;
  --ds-blue-500: #99ceff;
  --ds-blue-600: #52aeff;
  --ds-blue-700: #0072f5;
  --ds-blue-800: #0062d1;
  --ds-blue-900: #0068d6;
  --ds-blue-1000: #00254d;
  --ds-red-100: #fff5f5;
  --ds-red-200: #ffebeb;
  --ds-red-300: #ffe6e6;
  --ds-red-400: #fdd8d8;
  --ds-red-500: #f8baba;
  --ds-red-600: #f87275;
  --ds-red-700: #e5484d;
  --ds-red-800: #d93036;
  --ds-red-900: #ca2b30;
  --ds-red-1000: #391417;
  --ds-amber-100: #fff8eb;
  --ds-amber-200: #fff4d6;
  --ds-amber-300: #fef0cd;
  --ds-amber-400: #ffdd8f;
  --ds-amber-500: #ffc96b;
  --ds-amber-600: #f5b047;
  --ds-amber-700: #ffb224;
  --ds-amber-800: #ff990a;
  --ds-amber-900: #a35200;
  --ds-amber-1000: #4e2009;
  --ds-green-100: #f3fcf3;
  --ds-green-200: #ebfaeb;
  --ds-green-300: #daf6da;
  --ds-green-400: #c6f1c7;
  --ds-green-500: #99e69e;
  --ds-green-600: #6cda75;
  --ds-green-700: #45a557;
  --ds-green-800: #398e4a;
  --ds-green-900: #297a3a;
  --ds-green-1000: #1b311e;
  --ds-teal-100: #f2fdfb;
  --ds-teal-200: #e5faf6;
  --ds-teal-300: #d4f7f0;
  --ds-teal-400: #bef4eb;
  --ds-teal-500: #86ead9;
  --ds-teal-600: #45dec5;
  --ds-teal-700: #12a594;
  --ds-teal-800: #0d8c7d;
  --ds-teal-900: #067a6e;
  --ds-teal-1000: #073c34;
  --ds-purple-100: #fbf5ff;
  --ds-purple-200: #f9f1fe;
  --ds-purple-300: #f4e8fc;
  --ds-purple-400: #eddcf9;
  --ds-purple-500: #d5b1f1;
  --ds-purple-600: #bf89ec;
  --ds-purple-700: #8e4ec6;
  --ds-purple-800: #763da9;
  --ds-purple-900: #7820bc;
  --ds-purple-1000: #2e004d;
  --ds-pink-100: #fff5f8;
  --ds-pink-200: #feecf2;
  --ds-pink-300: #fce3ec;
  --ds-pink-400: #f9d7e2;
  --ds-pink-500: #f5b8cc;
  --ds-pink-600: #ee87a7;
  --ds-pink-700: #ea3e83;
  --ds-pink-800: #df2670;
  --ds-pink-900: #bd2864;
  --ds-pink-1000: #430a23;
  --ds-background-100: #fff;
  --ds-background-200: #f5f5f5;
}
