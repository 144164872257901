:root {
  font-size: 16px;
  --geist-space: 4px;
  --geist-space-2x: 8px;
  --geist-space-4x: 16px;
  --geist-space-8x: 32px;
  --geist-space-16x: 64px;
  --geist-space-24x: 96px;
  --geist-space-32x: 128px;
  --geist-space-48x: 192px;
  --geist-space-64x: 256px;
  --geist-space-small: 32px;
  --geist-space-medium: 40px;
  --geist-space-large: 48px;
  --geist-space-gap: 24px;
  --geist-space-gap-half: 12px;
  --geist-space-gap-quarter: var(--geist-space-2x);
  --geist-gap: var(--geist-space-gap);
  --geist-gap-half: var(--geist-space-gap-half);
  --geist-gap-quarter: var(--geist-space-gap-quarter);
  --geist-gap-double: var(--geist-space-large);
  --geist-gap-section: var(--geist-space-small);
  --geist-space-negative: -4px;
  --geist-space-2x-negative: -8px;
  --geist-space-4x-negative: -16px;
  --geist-space-8x-negative: -32px;
  --geist-space-16x-negative: -64px;
  --geist-space-24x-negative: -96px;
  --geist-space-32x-negative: -128px;
  --geist-space-48x-negative: -192px;
  --geist-space-64x-negative: -256px;
  --geist-space-small-negative: -32px;
  --geist-space-medium-negative: -40px;
  --geist-space-large-negative: -48px;
  --geist-space-gap-negative: -24px;
  --geist-space-gap-half-negative: -12px;
  --geist-space-gap-quarter-negative: var(--geist-space-2x-negative);
  --geist-gap-negative: var(--geist-space-gap-negative);
  --geist-gap-half-negative: var(--geist-space-gap-half-negative);
  --geist-gap-quarter-negative: var(--geist-space-gap-quarter-negative);
  --geist-gap-double-negative: var(--geist-space-large-negative);
  --geist-page-margin: var(--geist-space-gap);
  --geist-page-width: 1200px;
  --geist-page-width-with-margin: calc(var(--geist-page-width) + calc(2 * var(--geist-page-margin)));
  --geist-radius: 5px;
  --geist-marketing-radius: 8px;
  --geist-text-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.8), #000);
  --font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --font-mono: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
    monospace;
  --font-space-grotesk: Space Grotesk, var(--font-sans);
  --header-height: 64px;
  --header-border-bottom: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  --header-background: hsla(0, 0%, 100%, 0.8);
  --header-import-flow-background: hsla(0, 0%, 98%, 0.8);
  --geist-form-large-font: 1rem;
  --geist-form-large-line-height: 1.5rem;
  --geist-form-large-height: var(--geist-space-large);
  --geist-form-small-font: 0.875rem;
  --geist-form-small-line-height: 0.875rem;
  --geist-form-small-height: var(--geist-space-small);
  --geist-form-font: 0.875rem;
  --geist-form-line-height: 1.25rem;
  --geist-form-height: var(--geist-space-medium);
  --inset-border: 1px solid rgba(0, 0, 0, 0.1);
}
